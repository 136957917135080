import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Logo from "../feature/logo/Logo";
import { Link } from "react-router-dom";

const Header = (props) => {
  const { isSinglePage } = props;

  return (
    <header className="fixed z-50 flex w-screen items-center justify-center bg-[#00000023] px-8 py-4 backdrop-blur-md sm:justify-between lg:justify-between">
      <Logo />
      <div className="hidden items-center justify-center text-lg font-medium text-gray-200 sm:flex">
        {!isSinglePage && (
          <>
            <AnchorLink
              offset={150}
              href="#about"
              className="link-hover px-8 py-2"
            >
              Download
            </AnchorLink>
            <AnchorLink
              offset={150}
              href="#features"
              className="link-hover px-8 py-2"
            >
              Features
            </AnchorLink>
          </>
        )}
        {isSinglePage && (
          <>
            <Link to="/">
              <p className="link-hover px-8 py-2">Home</p>
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
