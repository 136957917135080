import React, { useState } from "react";
import CountrySelector from "./CountrySelector";
import { COUNTRIES } from "./countries";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  // Default this to a country's code to preselect it
  const [country, setCountry] = useState(i18n.language.substring(0, 2));

  const onSelectCountry = (value) => {
    i18n.changeLanguage(value);
    setCountry(value);
  };

  return (
    <>
      <CountrySelector
        id={"countries"}
        open={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        onChange={(val) => onSelectCountry(val)}
        // We use this type assertion because we are always sure this find will return a value but need to let TS know since it could technically return null
        selectedValue={COUNTRIES.find((option) => option.value === country)}
      />
    </>
  );
};

export default LanguageSelector;
