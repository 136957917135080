export const COUNTRIES = [
  {
    title: "EN",
    value: "en",
    flag: "us",
  },
  {
    title: "AZ",
    value: "az",
    flag: "az",
  },
  {
    title: "IT",
    value: "it",
    flag: "it",
  },
  {
    title: "RU",
    value: "ru",
    flag: "ru",
  },
  {
    title: "TR",
    value: "tr",
    flag: "tr",
  },
];
