import React, { useEffect } from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelector from "../components/ui/country/LanguageSelector";
import analytics from "../analytics/app-analytics.js";

const TermServices = () => {
  const { t } = useTranslation();

  const bl = <span className="font-bold" />;
  const xbl = <span className="text-xl font-bold" />;
  const rd = <span className="text-primary" />;
  const hl = <Link to="/" className="text-primary" />;
  const pl = <Link to="/privacy-policy" className="text-primary" />;
  const al5 = (
    <AnchorLink offset={150} href="#content_5" className="text-primary" />
  );
  const al13 = (
    <AnchorLink offset={150} href="#content_13" className="text-primary" />
  );
  const li = <li />;
  const nl = <br />;
  const contents = Array.from(Array(25).keys(), (x) => x + 1);

  useEffect(() => {
    analytics.logEventScreen();
  }, []);

  return (
    <div className="TermServices">
      <Header isSinglePage={true} />
      <div className="w-full px-10 pt-36 text-xl lg:px-24">
        <div className="text-lg">
          <span className="text-4xl font-bold">{t("tof_title")}</span>
          <br />
          <br />
          <LanguageSelector />
          <br />
          <span className="text-lg text-gray-300">{t("tof_last_updated")}</span>
          <br />
          <br />
          <span className="text-2xl font-bold">{t("tof_agreement_title")}</span>
          <br />
          <br />
          <Trans
            i18nKey="tof_aggrement_content"
            components={{
              bl,
              hl,
              nl,
            }}
          />
          <br />
          <br />
          <section id="content_0">
            <span className="text-xl">
              <span className="text-2xl font-bold">
                {t("tof_table_contents_heading")}
              </span>
              <br />
              <br />
              {contents.map((item, index) => (
                <AnchorLink
                  key={`tof_section_al_${item}`}
                  offset={150}
                  href={`#content_${item}`}
                  className="underline"
                >
                  {t(`tof_section_${item}_heading`)}
                  <br />
                </AnchorLink>
              ))}
            </span>
          </section>
          <br />
          <br />

          {contents.map((item, index) => (
            <section key={`tof_section_${item}`} id={`content_${item}`}>
              <span className="text-2xl font-bold">
                {t(`tof_section_${item}_heading`)}
              </span>
              <br />
              <br />
              <Trans
                i18nKey={`tof_section_${item}_content`}
                components={{
                  bl,
                  rd,
                  hl,
                  pl,
                  nl,
                  li,
                  xbl,
                  al5,
                  al13,
                }}
              />
              <br />
              <br />
            </section>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermServices;
