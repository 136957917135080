import React, { useEffect } from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LanguageSelector from "../components/ui/country/LanguageSelector";
import analytics from "../analytics/app-analytics.js";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const bl = <span className="font-bold" />;
  const xbl = <span className="text-xl font-bold" />;
  const rd = <span className="text-primary" />;
  const hl = <Link to="/" className="text-primary" />;
  const dl = <Link to="/deletion-data" className="text-primary" />;
  const tl = <Link to="/terms-of-services" className="text-primary" />;
  const ed = (
    <a
      href="https://ec.europa.eu/newsroom/article29/items/612080"
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer text-primary"
    >
      {" "}
    </a>
  );
  const ud = (
    <a
      href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer text-primary"
    >
      {" "}
    </a>
  );
  const al0 = (
    <AnchorLink offset={150} href="#content_0" className="text-primary" />
  );
  const al1 = (
    <AnchorLink offset={150} href="#content_1" className="text-primary" />
  );
  const al2 = (
    <AnchorLink offset={150} href="#content_2" className="text-primary" />
  );
  const al4 = (
    <AnchorLink offset={150} href="#content_4" className="text-primary" />
  );
  const al7 = (
    <AnchorLink offset={150} href="#content_7" className="text-primary" />
  );
  const al9 = (
    <AnchorLink offset={150} href="#content_9" className="text-primary" />
  );
  const li = <li></li>;
  const ul = (
    <ul className="ml-12 list-disc">
      <li></li>
    </ul>
  );
  const nl = <br />;

  const contents = Array.from(Array(14).keys(), (x) => x + 1);

  useEffect(() => {
    analytics.logEventScreen();
  }, []);

  return (
    <div className="PrivacyPolicy">
      <Header isSinglePage={true} />
      <div className="w-full px-10 pt-36 lg:px-24">
        <div className="text-lg">
          <span className="text-4xl font-bold">{t("privacy_title")}</span>
          <br />
          <br />
          <LanguageSelector />
          <br />
          <span className="text-lg text-gray-300">
            {t("privacy_last_updated")}
          </span>
          <br />
          <br />
          <Trans
            i18nKey="privacy_introduction"
            components={{
              bl,
              rd,
              hl,
              li,
              nl,
            }}
          />
          <br />
          <br />
          <span className="text-2xl font-bold">
            {t("privacy_summary_points_heading")}
          </span>
          <br />
          <br />
          <Trans
            i18nKey="privacy_summary_points_content"
            components={{
              bl,
              rd,
              dl,
              nl,
              al0,
              al1,
              al2,
              al4,
              al7,
              al9,
            }}
          />
          <br />
          <br />
          <section id="content_0">
            <span className="text-xl">
              <span className="text-2xl font-bold">
                {t("privacy_table_contents_heading")}
              </span>
              <br />
              <br />
              {contents.map((item, index) => (
                <AnchorLink
                  offset={150}
                  key={`privacy_section_al_${index}`}
                  href={`#content_${item}`}
                  className="underline"
                >
                  {t(`privacy_section_${item}_heading`)}
                  <br />
                </AnchorLink>
              ))}
            </span>
          </section>
          <br />
          <br />

          {contents.map((item, index) => (
            <section key={`privacy_section_${index}`} id={`content_${item}`}>
              <span className="text-2xl font-bold">
                {t(`privacy_section_${item}_heading`)}
              </span>
              <br />
              <br />
              <Trans
                i18nKey={`privacy_section_${item}_content`}
                components={{
                  bl,
                  xbl,
                  rd,
                  hl,
                  dl,
                  tl,
                  nl,
                  li,
                  ul,
                  ed,
                  ud,
                  al0,
                  al1,
                  al2,
                  al4,
                  al7,
                  al9,
                }}
              />
              <br />
              <br />
            </section>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
