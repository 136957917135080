import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermServices from "./TermServices";
import DeletionData from "./DeletionData";
import NotFoundPage from "./NotFoundPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-services" element={<TermServices />} />
      <Route path="/deletion-data" element={<DeletionData />} />
      <Route path="/day/:dayId" element={<Home />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
