import React, { useEffect, useRef } from "react";
import InfiniteHobbies from "../../components/feature/hobby/InfiniteHobbies";
import Header from "../../components/ui/Header";
import Footer from "../../components/ui/Footer";
import "./Home.css";
import { useInView } from "framer-motion";
import analytics from "../../analytics/app-analytics.js";

const Home = () => {
  const titleRef = useRef(null);
  const isTitleInView = useInView(titleRef, { once: true });

  const firstFeatureRef = useRef(null);
  const isFirstFeatureInView = useInView(firstFeatureRef, { once: true });

  const secondFeatureRef = useRef(null);
  const isSecondFeatureInView = useInView(secondFeatureRef, { once: true });

  useEffect(() => {
    analytics.logEventScreen();
  }, []);

  const onClickDownloadGoogle = () => {
    analytics.logEventClick("download-google-play");
  };

  return (
    <div className="Home">
      <Header></Header>
      <section className="relative flex flex-col lg:h-screen" id="about">
        <div className="grow-1 mt-36 flex h-full w-full flex-col sm:mt-40 lg:flex-row">
          <div className="order-2 mt-32 flex flex-1 cursor-default select-none items-center lg:order-1 lg:mt-0 lg:basis-3/5 2xl:basis-3/5">
            <div
              ref={titleRef}
              className="w-full px-6 sm:pl-12 md:pl-16 xl:pl-28"
            >
              <div
                className={`animation-transition ${
                  isTitleInView
                    ? "none opacity-100"
                    : ":translate-y-0 translate-y-52 opacity-0 md:-translate-x-96 md:translate-y-0"
                }`}
              >
                <p className="text-2xl font-bold sm:text-4xl 2xl:text-6xl">
                  One Day,
                  <br />
                  <span className="gradient-text relative">
                    {" "}
                    Endless Possibilities
                    <span className="absolute left-0 top-1/4 -z-50 h-2/3 w-full bg-[#591844f4] opacity-100 blur-[5px] filter sm:bg-[#591844c6] sm:blur-[15px]"></span>
                  </span>
                  <br /> with New Friends
                </p>
                <br />
                <p className="max-w-3xl text-lg text-gray-400 md:w-2/3 lg:max-w-lg xl:max-w-xl">
                  Teeday is the platform for anyone looking to make new friends,
                  discover new interests, and have fun in a unique and exciting
                  way. Our app lets you create your own groups and connect with
                  other individuals who share your interests and passions. With
                  Teeday, you can unlock new opportunities and experiences, all
                  in just 24 hours. <br />
                </p>
                <br />
                <br />
                <div className="flex flex-row flex-wrap gap-x-4 gap-y-4" onClick={onClickDownloadGoogle}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gaan.teeday"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/google_play.png"
                      className="w-30 h-10 sm:h-12"
                      alt=""
                    />
                  </a>
                  {/* <img
                    src="/app_store.svg"
                    className="h-10 w-fit sm:h-12"
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 flex flex-1 items-center justify-center lg:order-2 lg:basis-2/5 2xl:basis-3/5">
            <div
              className={`animation-transition ${
                isTitleInView
                  ? "none scale-100 opacity-100"
                  : "translate-y-52 scale-0 opacity-0"
              } relative h-auto w-2/3 max-w-sm lg:max-w-lg xl:max-w-7xl`}
            >
              <img className="scale-110" src="/app_screen.png" alt="" />
              <div className="absolute left-[10%] top-[20%] -z-50 h-3/4 w-3/4 bg-container opacity-40 blur-[20px] filter lg:opacity-40 lg:blur-[50px]"></div>
            </div>
          </div>
        </div>

        <InfiniteHobbies className="my-12 shrink-0" />

        <div className="absolute left-0 top-1/4 -z-50 hidden h-2/5 w-1/3 -translate-x-1/2 -translate-y-3/4 transform bg-[#7b285c69] opacity-40 blur-[70px] filter sm:block lg:opacity-30 lg:blur-[100px] 2xl:opacity-40 2xl:blur-[200px]"></div>
        <div className="absolute right-1/4 top-0 -z-50 hidden h-2/5 w-1/3 -translate-y-1/2 translate-x-1/2 transform bg-[#c23c5169] opacity-40 blur-[70px] filter sm:block lg:opacity-30 lg:blur-[100px] 2xl:opacity-40 2xl:blur-[200px]"></div>
        <div className="absolute bottom-0 left-1/4 -z-50 hidden h-1/6 w-1/2 -translate-x-1/2 transform bg-[#914cbc69] opacity-40 blur-[70px] filter sm:block lg:h-1/3 lg:w-1/3 lg:translate-y-1/2 lg:opacity-30 lg:blur-[100px] 2xl:opacity-30 2xl:blur-[200px]"></div>

        <div className="absolute left-1/2 top-0 -z-50 h-1/2 w-1/2 -translate-x-1/2 -translate-y-3/4 transform bg-[#431732c7] opacity-80 blur-[60px] filter sm:hidden"></div>
        <div className="absolute right-1/4 top-1/2 -z-50 h-2/5 w-1/2 -translate-y-1/2 transform bg-[#c23c5169] opacity-10 blur-[60px] filter sm:hidden"></div>
        <div className="absolute left-0 top-3/4 -z-50 h-1/4 w-screen -translate-y-1/2 transform bg-[#914cbc69] opacity-20 blur-[60px] filter sm:hidden"></div>
      </section>
      <section id="features">
        <div
          ref={firstFeatureRef}
          className="flex flex-col lg:h-screen lg:flex-row"
        >
          <div className="relative my-16 flex flex-1 items-center justify-center lg:order-1 lg:my-0 lg:basis-2/5 2xl:basis-3/5">
            <div
              className={`animation-transition ${
                isFirstFeatureInView
                  ? "none scale-100 opacity-100"
                  : "translate-y-52 scale-0 opacity-0"
              } relative h-auto w-2/3 max-w-sm lg:max-w-lg xl:max-w-6xl`}
            >
              <img src="/plan.png" className="scale-110" alt="" />
              <div className="absolute left-[10%] top-[20%] -z-50 h-3/4 w-3/4 bg-container opacity-60 blur-[50px] filter sm:blur-[100px]"></div>
            </div>
          </div>
          <div className="mt-8 h-full w-full flex-1 px-6 sm:px-12 md:px-16 lg:order-2 lg:mt-0 lg:flex lg:basis-2/5 lg:items-center lg:justify-center lg:pb-16 lg:pr-8 xl:px-8">
            <div
              className={`animation-transition ${
                isFirstFeatureInView
                  ? "none opacity-100"
                  : "translate-y-52  opacity-0 md:translate-x-96 md:translate-y-0"
              }`}
            >
              <p className="gradient-text-2 text-xl font-semibold sm:text-3xl xl:text-4xl 2xl:text-5xl">
                Plan Your Dream Day
                <br />
                Invite Others to Join
              </p>
              <br />
              <p className="max-w-3xl text-lg text-gray-400 lg:max-w-lg xl:max-w-xl">
                Create a customized plan for your unique day, whether it's{" "}
                exploring a new city, trying a new activity, or simply spending
                time with friends.
              </p>
            </div>
          </div>
        </div>
        <div
          ref={secondFeatureRef}
          className="flex flex-col lg:h-screen lg:flex-row"
        >
          <div className="order-2 mt-8 h-full w-full flex-1 justify-center px-6 py-10 sm:px-12 md:px-16 lg:order-1 lg:mt-0 lg:flex lg:basis-3/5 lg:items-center lg:justify-start lg:pb-16 lg:pl-[15%]">
            <div
              className={`animation-transition ${
                isFirstFeatureInView
                  ? "none opacity-100"
                  : "translate-y-52  opacity-0 md:translate-x-96 md:translate-y-0"
              }`}
            >
              <div
                className={`animation-transition ${
                  isSecondFeatureInView
                    ? "none opacity-100"
                    : "translate-y-52  opacity-0 md:-translate-x-96 md:translate-y-0"
                }`}
              >
                <p className="text-xl font-semibold sm:text-3xl xl:text-5xl 2xl:text-7xl">
                  Real-Time Chat
                  <br />
                  Unforgettable{" "}
                  <span className="gradient-text-3"> Memories</span>
                </p>
                <br />
                <p className="max-w-4xl text-lg text-gray-400 md:w-4/5 lg:max-w-xl xl:max-w-3xl">
                  With Teeday, you can invite others to join your adventure and
                  share your plan with like-minded individuals who are also
                  looking for a unique and fun experience.
                </p>
              </div>
            </div>
          </div>
          <div className="relative order-1 mt-24 flex flex-1 items-center justify-center lg:order-2 lg:mt-0 lg:basis-2/5 2xl:basis-2/5">
            <div
              className={`animation-transition ${
                isSecondFeatureInView
                  ? "none scale-100 opacity-100"
                  : "translate-y-52 scale-0 opacity-0"
              } relative h-auto w-2/3 max-w-sm lg:max-w-lg xl:max-w-6xl`}
            >
              <img src="/chat.png" className="mx-auto" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Home;
