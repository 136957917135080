import { getAnalytics, logEvent } from "firebase/analytics";

const logEventScreen = () => {
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: window.location.pathname,
    firebase_screen_class: window.location.pathname,
  });
};

const logEventClick = (item_id) => {
  const analytics = getAnalytics();
  logEvent(analytics, "select_content", {
    content_type: "button",
    item_id,
  });
};

const exportedObject = {
  logEventScreen,
  logEventClick,
};

export default exportedObject;
