import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="relative flex w-full flex-col justify-end px-20">
      <motion.img
        className="mx-auto mt-24 h-24 w-24 lg:h-64 lg:w-64"
        src="/logo.svg"
        alt="logo"
        animate={{
          x: [0, -15, 0, 15, 0, 0],
          y: [0, -15, -15, -15, 15, 0],
          rotate: [0, 10, 0, -10, 0],
        }}
        transition={{ repeat: Infinity, duration: 5 }}
        initial={{ x: 0, y: 0 }}
      ></motion.img>
      <p className="text-center text-4xl font-bold lg:text-6xl">TeeDay</p>
      <div className="h-24 lg:h-60"></div>
      <div className="flex w-full flex-col items-center justify-between space-y-6 pb-12 lg:flex-row lg:space-y-0">
        <p className="order-2 mt-12 text-center lg:order-1 lg:mt-0 lg:text-start">
          Copyright © 2023 GAAN LLC
        </p>

        <div className="order-1 flex flex-col space-y-6 lg:order-2 lg:flex-row lg:space-x-6 lg:space-y-0">
          <Link to="/privacy-policy">
            <p className="link-hover text-center normal-case lg:text-start">
              {t("privacy_title")}
            </p>
          </Link>
          <Link to="/terms-of-services">
            <p className="link-hover text-center normal-case lg:text-start">
              {t("tof_title")}
            </p>
          </Link>
          <Link to="/deletion-data">
            <p className="link-hover text-center normal-case lg:text-start">
              {t("deletion_data")}
            </p>
          </Link>
        </div>
      </div>
      <div className="absolute bottom-0 left-1/2 -z-50 hidden h-2/3 w-1/2 -translate-x-1/2 transform bg-[#f431fb64] opacity-20 blur-[250px] filter sm:block"></div>
      <div className="absolute bottom-1/2 left-1/2 -z-50 h-1/3 w-2/3 -translate-x-1/2  transform bg-[#f431fb64] opacity-40 blur-[60px] filter sm:hidden"></div>
    </footer>
  );
};

export default Footer;
