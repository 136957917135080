import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <div className="flex items-center space-x-4">
        <img
          className="h-12 w-12 cursor-pointer md:h-16 md:w-16"
          src="/logo.svg"
          alt=""
        />
        <p className="cursor-pointer font-semibold text-xl md:text-3xl">
          TeeDay
        </p>
      </div>
    </Link>
  );
};

export default Logo;
