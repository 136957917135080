import React, { useEffect } from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelector from "../components/ui/country/LanguageSelector";
import analytics from "../analytics/app-analytics.js";

const DeletionData = () => {
  const { t } = useTranslation();

  const rd = <span className="text-primary" />;
  const nl = <br />;
  const hl = <Link to="/" className="text-primary" />;

  useEffect(() => {
    analytics.logEventScreen();
  }, []);

  return (
    <div className="DeletionData">
      <Header isSinglePage={true} />
      <div className="w-full px-10 pt-36 lg:px-24">
        <p className="text-4xl font-bold">{t("deletion_title")}</p>
        <br />
        <LanguageSelector />
        <br />
        <p className="text-xl">
          <Trans
            i18nKey="deletion_content"
            components={{
              rd,
              nl,
              hl,
            }}
          />
          <br />
          <br />
          <br />
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DeletionData;
