import React from "react";

const ItemHobby = (props) => {
  const { title } = props;
  return (
    <div className="ItemHobby flex items-center justify-center rounded-2xl bg-[#06060696] px-12 py-4">
      <p className="whitespace-nowrap text-center text-lg font-semibold text-neutral-400">
        #{title}
      </p>
    </div>
  );
};

export default ItemHobby;
