import React, { useRef } from "react";
import ItemHobby from "./ItemHobby";
import { motion } from "framer-motion";

const InfiniteHobbies = (props) => {
  const windowWidthRef = useRef(window.innerWidth);
  const windowWidth = windowWidthRef.current;
  const { className } = props;
  const hobbies = [
    "Tech & Computer",
    "Entertainment",
    "Studying",
    "Cooking",
    "Reading",
    "Outdoor",
    "Social",
    "Sports",
    "Gaming",
    "Music",
    "Yoga",
    "Art",
    "Travel",
    "Pets",
    "Crafts",
  ];

  const hobbyComponents = hobbies.map((element, index) => (
    <div key={index}>
      <ItemHobby title={element} />
    </div>
  ));

  return (
    <div className={`${className} InfiniteHobbies`}>
      <div className="relative w-full overflow-hidden">
        <motion.div
          className="w-[300%] lg:w-[150%]"
          animate={{
            translateX: ["0%", windowWidth > 800 ? "-25%" : "-200%", "0%"],
          }}
          transition={{
            repeat: Infinity,
            duration: windowWidth > 800 ? 60 : 400,
          }}
        >
          <div className="flex gap-x-10 py-4">
            {hobbyComponents}
            {hobbyComponents}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default InfiniteHobbies;
