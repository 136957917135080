import React from "react";
import Header from "../components/ui/Header";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage">
      <Header isSinglePage={true} />
      <div className="flex h-[90vh] w-full items-center justify-center px-10 pt-36 text-xl lg:px-24">
        <p className="text-2xl md:text-5xl lg:text-6xl text-center font-bold">404 Not Found</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
